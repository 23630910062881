<template>
    <div class="basic-input">
        <slot name="prepend"></slot>
        <textarea
            v-if="textarea"
            class="form-control input-grey text-area"
            :value="modelValue"
            :maxlength="maxlength"
            :name="name"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :disabled="disabled"
            @input="onInput"
            @blur="handleBlur"
            :style="{
                resize: resizeTextarea ? 'vertical' : 'none',
            }"
        />
        <div v-else-if="type === 'checkbox'" class="checkbox-wrapper">
            <input
                type="checkbox"
                :checked="modelValue"
                @input="onInput"
                class="form-check-input"
            />
        </div>
        <div
            :class="{ 'form-floating': floating }"
            v-else
            class="input-wrapper"
        >
            <input
                class="form-control input-grey"
                ref="inputElement"
                :class="{
                    'border-radius-left-only': name === 'new-username',
                }"
                id="floatingInput"
                :value="inputValue"
                :name="name"
                :type="type"
                :autocomplete="autocomplete"
                :placeholder="floating ? '' : placeholder"
                :disabled="disabled"
                :maxlength="maxlength"
                @input="onInput"
                @blur="handleBlur"
            />
            <label for="floatingInput" v-if="floating">{{ placeholder }}</label>
            <slot name="append"></slot>
        </div>

        <slot name="append"></slot>
        <span
            class="error-message"
            v-if="
                (errorMessage || meta.valid || errorContent) &&
                meta.path !== 'new-username'
            "
        >
            {{ errorMessage }}
        </span>
    </div>
</template>

<script>
import { useField } from "vee-validate"
import { watch } from "vue"

export default {
    name: "BasicInput",
    props: {
        modelValue: {
            type: String,
            default: "",
        },
        rules: {
            type: Function,
        },
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: "text",
        },
        placeholder: {
            type: String,
            default: "",
        },
        autocomplete: {
            type: String,
            default: "",
        },
        autofocus: {
            type: Boolean,
            default: false,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        textarea: {
            type: Boolean,
            default: false,
        },
        list: {
            type: String,
            default: null,
            required: false,
        },
        floating: {
            type: Boolean,
            default: false,
        },
        ref: {
            type: String,
            default: "",
        },
        textTransform: {
            type: String,
            default: null,
            required: false,
        },
        lastData: {
            type: String,
            default: null,
        },
        maxlength: {
            type: Number,
            default: null,
        },
        resizeTextarea: {
            type: Boolean,
            default: true,
        },
        needErrorMessage: {
            type: Boolean,
            default: true,
        },
    },
    emits: ["update:modelValue"],
    mounted() {
        if (this.autofocus) {
            this.$refs.input.focus()
        }

        if (this.lastData) {
            this.inputValue = this.lastData
        }
    },
    watch: {
        modelValue: {
            immediate: true,
            handler(value) {
                this.initialValue = value
            },
        },
        maxlength: {
            type: Number,
            default: null,
        },
    },
    setup(props, { emit }) {
        const {
            value: inputValue,
            errorMessage,
            handleChange,
            handleBlur,
            meta,
        } = useField(props.name, props.rules, {
            initialValue: props.modelValue,
        })

        watch(
            () => props.modelValue,
            (newValue) => {
                if (newValue !== inputValue.value) {
                    inputValue.value = newValue
                }
            }
        )

        //For any change in the input, update the modelValue and emit the update:modelValue at the parent component
        const onInput = (event) => {
            let start = event.target.selectionStart
            switch (props.textTransform) {
                case "lowercase":
                    event.target.value = event.target.value.toLowerCase()
                    if (start !== event.target.value.length) {
                        event.currentTarget.setSelectionRange(start, start)
                    }
                    break
                case "uppercase":
                    break
                default:
                    break
            }
            handleChange(event)

            emit("update:modelValue", event.target.value)
        }

        emit("errorMessage", errorMessage)

        return {
            onInput,
            handleChange,
            handleBlur,
            errorMessage,
            inputValue,
            meta,
        }
    },
    methods: {
        focusInput() {
            this.$refs.inputElement.focus()
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.form-control {
    border-radius: 10px;
    padding: 1em 1em;
    font-family: $font-avenir-roman;
    font-size: $small;
    color: $blue-neutral;
}

.basic-input {
    .input-wrapper {
        width: 100%;

        input {
            border: 0;
            &:focus {
                outline: none;
                box-shadow: inset 0 0 0 0.25rem
                    rgba($blue-medium-pale, $alpha: 1);
                transition: all 200ms;
                border: 0;
            }
        }
    }

    .checkbox-wrapper {
        width: 100%;
        height: 100%;

        input {
            width: 100%;
            height: 100%;
        }
    }
}

.time-input {
    font-size: $small;
}

.input-grey {
    background-color: $light-grey;
    border-color: $light-grey;
}

.input-grey::placeholder {
    color: $grey;
}

.input-grey:focus {
    background-color: $light-grey;
}

.error-message {
    color: $orange;
    font-family: $font_avenir_book;
    font-size: $small;
    width: 100%;
    margin-top: 5px;
}

.paddingText {
    padding-left: 4em;
    padding-right: 3em;
}

.text-area {
    min-height: 100%;
}
.form-floating {
    & > label {
        display: flex;
        justify-content: start;
        align-items: center;
        transform-origin: top left !important;
    }
    .form-control-plaintext ~ label,
    .form-control:focus ~ label,
    .form-control:not(:placeholder-shown) ~ label,
    .form-select ~ label {
        color: $blue-neutral;

        &::after {
            background-color: transparent;
        }
    }
}

.border-radius-left-only {
    border-radius: 0px 10px 10px 0px !important;
}
</style>
