// initial state
const state = () => ({
    jwt: null,
    jwtRenew: null,
    expireAt: null,
    renewExpireAt: null,
    uuid: null,
    // mfa signature
    signature: null,
    // double auth code
    code: null,
    rights: [],
    privileges: [],
    isAdmin: false,
    // TEMP
    intratoneDev2SID: null,
    logged: false,
    //environment
    param: null,
})
// getters
const getters = {
    getJwt: (state) => {
        return state.jwt
    },
    getJwtRenew: (state) => {
        return state.jwtRenew
    },
    getExpireAt: (state) => {
        return state.expireAt
    },
    getUuid: (state) => {
        return state.uuid
    },
    getCode: (state) => {
        return state.code
    },
    getSignature: (state) => {
        return state.signature
    },
    // TEMP
    getIntratoneDev2SID: (state) => {
        return state.intratoneDev2SID
    },
    getPrivileges: (state) => {
        return state.privileges
    },
    getParam: (state) => {
        return state.param
    },
}

// actions
const actions = {}

const mutations = {
    setJwtValues(state, data) {
        state.jwt = data.jwt
        state.jwtRenew = data.jwtRenew
        state.expireAt = data.expireAt
        state.renewExpireAt = data.renewExpireAt
    },
    setLogged(state, data) {
        state.logged = data
    },
    setRights(state, data) {
        state.rights = data
    },
    setUuid(state, data) {
        state.uuid = data
    },
    setCode(state, data) {
        state.code = data
    },
    setSignature(state, data) {
        state.signature = data
    },
    setPrivileges(state, data) {
        state.privileges = data
    },
    // TEMP
    setIntratoneDev2SID(state, data) {
        state.intratoneDev2SID = data
    },
    setState(state, data) {
        state.jwt = data.jwt
        state.jwtRenew = data.jwtRenew
        state.expireAt = data.expireAt
        state.renewExpireAt = data.renewExpireAt
        state.logged = data.logged
        state.rights = data.rights
        state.privileges = data.privileges
    },
    setParam(state, data) {
        state.param = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
