export default {
  "button": {
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "resetStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty the log ? "])},
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more"])},
    "seeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See less"])},
    "seeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See"])},
    "ignore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])}
  },
  "link_words": {
    "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])}
  },
  "input": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in this field"])},
    "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is mandatory"])},
    "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is invalid"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field must contain numbers only"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This address is not valid"])},
    "length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Must be ", _interpolate(_named("length")), " characters long"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])}
  },
  "errors": {
    "oups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops !"])},
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access denied"])},
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal error"])},
    "contactSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please contact technical support."])}
  },
  "alerts": {
    "connection": {
      "connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are logged in as ", _interpolate(_named("name"))])},
      "disconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are logged out"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your session has expired, please log in again"])},
      "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion impossible"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid login details"])},
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been registered"])}
    },
    "form": {
      "incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some of the information on the form is incorrect or incomplete"])},
      "different": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password and password confirmation must be identical"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept the terms of use in order to create a new account"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to select a country"])},
      "industryField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to select a sector of activity"])},
      "invalid_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'heure renseignée n'est pas valide"])},
      "required_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez sélectionner un jour"])},
      "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select a Vigik+ county"])},
      "residence_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez sélectionner un type de résidence"])}
    },
    "version": {
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to load new update"])},
      "new_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal update"])}
    },
    "notifications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["You have ", _interpolate(_named("count")), " notification"]), _normalize(["You have ", _interpolate(_named("count")), " notifications"])])},
    "globalNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New notifications"])},
    "no_mobile_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page has not yet been optimised for mobile viewing. We are currently working on it."])},
    "access": {
      "opendoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening command was sent"])}
    },
    "residence": {
      "rgpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos préférences RGPD ont été sauvegardés"])}
    },
    "errors": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This ID is already in use!"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This fob is already registered in this stairwell!"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This keypad code already exists for this access!"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This telephone code already exists for this access!"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This combination of name and identifier already exists for this flat!"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This speed dial number  is already in use!"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This holiday already exists!"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This fob is already used as a master key!"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have exceeded the quotas (Nb of cards...) of your GSM contract!"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your GSM contract number is not referenced!"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your central will not be updated with this phone number for pricing reasons!"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorised to modify this service!"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This access is used by one or more authorisations or connected to a central unit, you cannot delete it!"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This authorisation is used by one or more fobs,you cannot delete it!"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is currently only available on Internet Explorer!"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please create flats in order to program fobs!"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please define the parameters of the central unit beforehand!"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This central unit name is already in use!"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This apartment name is already in use!"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This stairwell name is already in use!"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This flat name is already used for this stairwell!"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This authoursiation name is already in use!"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This access name is already in use!"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Master key name already exists!"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot create more than 11 keypad codes per door!"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot create more than 10 telephone codes per door!"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot create more than 1 keypad code for this door!"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot have more than 16 read/write accesses for one authorisation! Read/Write for one authorisation!"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot program more than 6 stairwells Read/Write type on this Master key!"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot program more than 6 Read/Write accesses on this Pass! Read/Write accesses on this Master key!"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot program more than 6 Read/Write accesses on this Pass! Read/Write accesses on this master key!"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This central unit is already in use!"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the module or contract number you have entered."])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some GSM modules could not be updated due to GSM package quotas!"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some central units are Read/Write and need to be commissioned!"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature requires the installation of the programmer (only available under Internet Explorer)!"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This access is used for an authorisation - you cannot change its operation!"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This access is used by keycodes - you cannot change its operation!"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only connect scrolling intercom accesses to this connector!"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot connect a scrolling intercom type access to this connector!"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must first connect this access to a central unit!"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This material is only available in France!"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot program more than 18 Read/Write residences on this master key!"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot program more than 18 Read/Write stairwells on this master key!"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot program more than 18 Read/Write accesses on this master key!"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot program Read/Write (HF) residences on this master key!"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot program Read/Write (HF) stairwells on this master key!"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot program Read/Write (HF) access on this Master Key!"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot program more than 5 Read/Write (HF) properties on this master key,"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot program more than 5 Read/Write (HF) stairwells on this master key,"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot program more than 5 Read/Write (HF) accesses on this master key,"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot have more than 5 Read/Write (HF) accesses for one authorisation!"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must first connect this central unit to an access!"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The serial number of the central unit is not valid"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot have more than 10 LIGHT accesses for one authorisation!"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must use fobs or transmitters to open these access types!"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot have more than 10 LIGHT accesses programmed on one fob!"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No other flat available in this stairwell!"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import completed successfully"])},
      "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot delete the extension card! Hardware is connected to connector 3 or 4"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your USB programmer does not support this feature!"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while loading the backup, please try again!"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The inserted card is not a backup card!"])},
      "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check if the backup card is inserted correctly!"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while loading the backup, please try again!"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a backup connected to the internal connector and one in the smart card reader..."])},
      "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This mobile key is already programmed in this stairwell!"])},
      "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your USB programmer is not compatible with this functionality!"])},
      "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while loading the memory, please try again!"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The inserted card is not a memory card!"])},
      "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check if the memory card is inserted correctly!"])},
      "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while loading the memory, please try again!"])},
      "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The card inserted is not the memory card for this central unit!"])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum number of keypad codes for this access!"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This access cannot be connected to this type of connector!"])},
      "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum number of SMS service attempts!"])},
      "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while sending the SMS, please try again!"])},
      "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum number of keypad codes for this central unit!"])},
      "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This access can only be connected to connector no. 1!"])},
      "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This access cannot be connected to this type of central unit or this connector number!"])},
      "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These codes are unknown!"])},
      "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This product code is already activated!"])},
      "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum number of fobs for this flat!"])},
      "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum number of transmitters for this flat!"])},
      "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only letters (a-z)(A-Z), numbers (0-9) and characters (.,-,_) are allowed, spaces are not allowed."])},
      "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can no longer create records in this flat!"])},
      "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one property!"])},
      "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one stairwell!"])},
      "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one door!"])},
      "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one access!"])},
      "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one agency!"])},
      "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This fob does not exist"])},
      "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum number of assignable manager fobs!"])},
      "97": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user already has this type of key"])},
      "100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This record has been deleted!"])},
      "101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the number of holidays allowed!"])},
      "102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This use case is used in other accesses and cannot be deleted!"])},
      "103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This week is used in a time slot!"])},
      "104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User does not exist!"])},
      "105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not accessible!"])},
      "106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This username is already in use!"])},
      "107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This building name is already in use!"])},
      "108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data to process!"])},
      "109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This fob is not present!"])},
      "110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while launching the update, please try again later!"])},
      "111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This fob is not valid or is already in use!"])},
      "112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This barcode does not exist!"])},
      "113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This barcode is already in use!"])},
      "114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account creation service is not accessible, please try again later."])},
      "115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This account cannot be moved."])},
      "116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user already has this type of key."])},
      "117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have any sub-accounts yet."])},
      "120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a memory connected to the internal connector and one in the smart card reader..."])},
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This time name is already in use!"])},
      "201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This time range is used and cannot be deleted!"])},
      "202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data matches your search ..."])},
      "203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password entered is not valid ..."])},
      "204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one recipient!"])},
      "301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention you have exceeded your subscription, you cannot update your central ..."])},
      "302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention the GSM phone number is empty, you cannot update your central..."])},
      "303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention you have exceeded the maximum number of International phones allowed..."])},
      "304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention your package is not compatible with this hardware configuration."])},
      "305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that your package is not authorised to use video calls, please subscribe to the videoo option or uncheck the video call option on the relevant forms"])},
      "306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot register this type of IntraBox Eco kit in a basic property."])},
      "700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may not program more than ten fobs of this type."])},
      "800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This GSM contract is already in use!"])},
      "801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. Please contact support."])},
      "802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the GSM module number or the phone number."])},
      "803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no hardware associated with this contract."])},
      "804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This type of contract is not compatible with this type of property."])},
      "805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To register this module, start by choosing your equipment (intercom, reader...) then \" central unit settings\"."])},
      "806": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention your contract is suspended, please contact INTRATONE (02 51 57 03 07) !"])},
      "1001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An update of the notice board is in progress… Please wait ..."])},
      "1002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The text is too long, please shorten it ..."])},
      "1003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to select at least the column flat name!"])},
      "1004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One column indicates the name of the record, but the primary phone is missing!"])},
      "1005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has interrupted the import!"])},
      "1006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A secondary phone number is not valid!"])},
      "1007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An invalid primary phone number!"])},
      "1008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An invalid username!"])},
      "1009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An invalid name!"])},
      "1010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A flat name is not valid!"])},
      "1011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An invalid serial number!"])},
      "1012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A primary telephone number is missing!"])},
      "1013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A username is entered without a record name!"])},
      "1014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A secondary phone number is entered without a record name!"])},
      "1015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A speed dial number is entered without a record name!"])},
      "1016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A display group is entered without a record name!"])},
      "1017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A primary telephone number is entered without a record name!"])},
      "1018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image format is not supported!"])},
      "1019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced management is not possible, the user has global access to properties!"])},
      "1020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A speed dial number is already in use!"])},
      "1021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The image size is too big!"])},
      "1022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to select a column for the blocks and a column for the flat names!"])},
      "1023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This image is used, deleting it is impossible!"])},
      "1024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This file type is not compatible!"])},
      "1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error when sending data"])},
      "1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The display time must be a positive number"])},
      "1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The start date is not valid"])},
      "1203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The end date is not valid"])},
      "1204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The start date must be before the end date"])},
      "1205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while saving"])},
      "1206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a file!"])},
      "1207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This file could not be imported!"])},
      "1300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please define the access concerned"])},
      "1400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletion of tenants completed..."])},
      "1401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletion of fobs and transmitters completed..."])},
      "1500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some data in the form is not valid"])},
      "1600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flat information cannot be found"])},
      "1601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number not formatted correctly"])},
      "1602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error when retrieving phone number"])},
      "1603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The fob cannot be added"])},
      "1604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error retrieving fob information"])},
      "1605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough fobs of a certain type"])},
      "1606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many fobs of a certain type"])},
      "1607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to retrieve programming information"])},
      "1608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error when creating the file"])},
      "1609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error when updating the file"])},
      "1610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file is already associated with a telephone number"])},
      "1611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: several records in the flat"])},
      "1612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error when retrieving information from the files"])},
      "1613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error when creating a fob"])},
      "1615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that this action requires that you remove the Connect.iT antenna connected to your module."])},
      "1700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: Your account is already an administrator account"])},
      "1701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to become an administrator account"])},
      "1702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible to become an administrator: your email address is not filled in"])},
      "1800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: The name of the central unit must be filled in"])},
      "1801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: Configuration problem"])},
      "1802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: You must enter the name of the access"])},
      "1803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: Your contract does not allow the addition of this equipment"])},
      "1804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: The equipment is already registered"])},
      "1805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: Unable to identify the contract, complete the information"])},
      "2016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while requesting an account."])},
      "2019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred, please try again later."])},
      "2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Function blocked by your administrator."])},
      "API_RESTRICTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary authorisations for this action."])},
      "PARAMS_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. Please try again later."])},
      "PARAMS_MISSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. Please try again later."])},
      "ACCES_TYPEEXIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur : Le type d'accès est déjà déclaré"])},
      "ACCES_TYPEFAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la demande de création d'un type d'accès."])},
      "POLICY_RESTRICTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary authorisations for this action."])},
      "WD_0_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un créneau horaire du lundi à une heure de fin supérieur à celle du début"])},
      "WD_1_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un créneau horaire du mardi à une heure de fin supérieur à celle du début"])},
      "WD_2_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un créneau horaire du mercredi à une heure de fin supérieur à celle du début"])},
      "WD_3_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un créneau horaire du jeudi à une heure de fin supérieur à celle du début"])},
      "WD_4_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un créneau horaire du vendredi à une heure de fin supérieur à celle du début"])},
      "WD_5_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un créneau horaire du samedi à une heure de fin supérieur à celle du début"])},
      "WD_6_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un créneau horaire du dimanche à une heure de fin supérieur à celle du début"])},
      "WD_0_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un chevauchement entre 2 créneaux horaires a été détecté pour le lundi"])},
      "WD_1_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un chevauchement entre 2 créneaux horaires a été détecté pour le mardi"])},
      "WD_2_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un chevauchement entre 2 créneaux horaires a été détecté pour le mercredi"])},
      "WD_3_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un chevauchement entre 2 créneaux horaires a été détecté pour le jeudi"])},
      "WD_4_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un chevauchement entre 2 créneaux horaires a été détecté pour le vendredi"])},
      "WD_5_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un chevauchement entre 2 créneaux horaires a été détecté pour le samedi"])},
      "WD_6_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un chevauchement entre 2 créneaux horaires a été détecté pour le dimanche"])},
      "WD_0_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. Please try again later."])},
      "WD_1_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. Please try again later."])},
      "WD_2_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. Please try again later."])},
      "WD_3_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. Please try again later."])},
      "WD_4_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. Please try again later."])},
      "WD_5_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. Please try again later."])},
      "WD_6_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. Please try again later."])},
      "2021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of the fields filled in is incorrect. Please check it."])}
    }
  },
  "language": {
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a language"])}
  },
  "global": {
    "entities": {
      "residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residence"])},
      "residenceType": {
        "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic property"])},
        "simplified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplified property"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full property"])}
      },
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
      "stairwell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block"])},
      "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flat"])},
      "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
      "resident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant file"])}
    },
    "status": {
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
      "updateAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update all"])},
      "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to date"])},
      "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
      "needUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Needs updating"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to update"])},
      "commissioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation"])},
      "optionPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass function"])},
      "updateResidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications de votre résidence appliquées"])}
    },
    "modal": {
      "edit-time-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit time range"])},
      "create-time-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a time range"])},
      "copie-day-time-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier les horaires"])},
      "rgpd": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["GDPR standards compliant of property's events: ", _interpolate(_named("name"))])},
      "valid": {
        "week-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider les nouvelles plages horaires"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel the modifications ?"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must validate the modifications"])},
        "raz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
        "access-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider le nouveau type d'accès"])},
        "delete-access-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to remove this type of access?"])},
        "delete-residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the property"])},
        "politicsUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik Modification Pending"])},
        "politicsUpdateText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that any customizations made previously will be removed if you validate this update. Would you like to use it ?"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset to factory defaults"])}
      },
      "vigik": {
        "not-enough-time-slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum number of time slots per day. Please delete some to create new ones."])}
      },
      "providersStatus": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Récapitulatif des modifications (", _interpolate(_named("count")), ")"])},
        "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
        "authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorized"])},
        "noAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized"])}
      },
      "doubleAuth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double authentification"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A one-time validation code has been sent to this e-mail address:"])},
        "validationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation code"])},
        "error": {
          "attempt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["The validation code is incorrect. You have ", _interpolate(_named("count")), " attempts left"]), _normalize(["The validation code is incorrect. You have ", _interpolate(_named("count")), " attempts left"])])},
          "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please restart the procedure. You will be redirected to the login page in 10 seconds."])},
          "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The code is no longer valid."])}
        }
      }
    },
    "array": {
      "emptyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty list"])},
      "noSearchData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no data matching your search"])}
    }
  },
  "weekType": {
    "weekday": {
      "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
      "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
      "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
      "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
      "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
      "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
      "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])}
    },
    "copie-choice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["On which days do you want to copy the ", _interpolate(_named("day")), " schedule ?"])},
    "max-different-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum number of different days in the calendar. Do you want to reuse another day's timetable?"])}
  },
  "vigik": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik + configuration"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access type name"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy the operation"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to cancel the creation of a new access type?"])},
      "create-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to create this new type of access?"])},
      "openingPriority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorité d'ouverture"])},
      "obligatoryFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required field"])},
      "minimalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The time slot must be longer than 15 minutes"])},
      "opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])}
    },
    "modale": {
      "copySchedule": {
        "copyTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le"])},
        "onNextDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur les jours suivants"])}
      },
      "createSchedule": {
        "limitAtFiveSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas créer plus de cinq plages horaires par jour."])},
        "existingSchedules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il existe déjà un créneau sur ces horaires"])}
      }
    },
    "global": {
      "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
      "customProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized service providers"])},
      "bannedProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohibited Providers"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "whitelisted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Custom"]), _normalize([_interpolate(_named("count")), " custom"]), _normalize([_interpolate(_named("count")), " custom"])])},
      "blacklisted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Forbidden"]), _normalize([_interpolate(_named("count")), " forbidden"]), _normalize([_interpolate(_named("count")), " prohibited"])])},
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By default"])},
      "activitiesAllows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité autorisée"])},
      "activitiesBanned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité non autorisée"])},
      "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
      "allAutorised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous autorisés"])}
    },
    "navigation": {
      "listAccessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the list of access types"])},
      "listPersonalisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the list of customizations"])},
      "list-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the list of suppliers"])},
      "list-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la liste des activités"])},
      "searchCharacterLimiter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir au minimum 3 caractères"])}
    },
    "config": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When creating a Vigik + compatible reader, you must choose the type of reader"])},
      "subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This allows you to define the Vigik profile that will be used"])},
      "accessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of access"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "accessNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accesses"])},
      "createAccessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an access type"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "confirmPoliticsUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new Vigik policy is available. Would you like to use it?"])}
    },
    "custom": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Customize access type ", _interpolate(_named("name"))])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define in this page the activities of the providers"])},
      "activityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the activity"])},
      "providerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of Provider"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohibited providers"])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorized"])},
      "noAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized"])},
      "reset-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset the default schedules"])},
      "reset-to-zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remettre les horaires par défaut"])},
      "reset-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset the default access types"])},
      "raz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to reset to the default settings ?"])},
      "activityStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de l'activité"])},
      "activityTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaires de l'activité"])},
      "providerTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider's opening hours"])},
      "timetablesCustomised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalized schedules"])},
      "timetablesDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Schedules"])}
    },
    "schedule": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select the desired times for ", _interpolate(_named("name"))])},
      "edit-schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to save your changes?"])},
      "edit-schedule-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to cancel your changes?"])},
      "update-schedule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les horaires de ", _interpolate(_named("name")), " ont bien été modifiés."])},
      "reset-schedule-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez modifier les horaires pour pouvoir les reinitialiser."])},
      "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timetables"])},
      "selectAllDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner la journée entière"])}
    },
    "activity": {
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for an activity or a service provider"])},
      "modification": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " modification"]), _normalize([_interpolate(_named("count")), " modifications"])])}
    },
    "provider": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Authorized providers for ", _interpolate(_named("name"))])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the provider"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a provider to customize"])},
      "customProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize a provider"])},
      "searchCustomProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a provider to customize"])},
      "forbiddenProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banning a provider"])},
      "searchForbiddenProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a provider to ban"])}
    },
    "access": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["List of accesses of type ", _interpolate(_named("name"))])},
      "alert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez ", _interpolate(_named("count")), " accès hors de votre gestion"])},
      "residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the residence"])},
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
      "cage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cage"])},
      "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
      "delete-access-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to remove this type of access?"])},
      "update-access-check": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les accès de ", _interpolate(_named("name")), " ont bien été modifiés."])},
      "create-access-type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le type d'accès ", _interpolate(_named("name")), " a bien été créé."])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, toute personnalisation effectuée précédemment sera supprimée si vous validez cette restauration. Souhaitez-vous restaurer les paramètres par défaut ?"])}
    }
  },
  "exportFobs": {
    "fobsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of fobs"])},
    "fobsNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 fob"]), _normalize(["1 fob"]), _normalize([_interpolate(_named("count")), " fobs"])])},
    "fobsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minCount")), " at ", _interpolate(_named("maxCount"))])},
    "fobsSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° of fobs"])},
    "fobsLastUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last date of use"])},
    "fobsFilterDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Choosing a period -"])},
    "fobsFilterOneWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over 1 week"])},
    "fobsFilterTwoWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over 2 weeks"])},
    "fobsFilterOneMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over 1 month"])},
    "fobsFilterThreeMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over 3 months"])},
    "fobsFilterSixMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over 6 months"])},
    "fobsFilterOneYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over 1 year"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a fob or apartment"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blank fob list"])},
    "noDataSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data matches your search"])},
    "fobsDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove this fob? This action is irreversible."])}
  },
  "publicHome": {
    "residenceTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer a property"])},
    "manuals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources and product information"])},
    "returnCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete a return slip"])},
    "programmerSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install USB programmer"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review terms and conditions"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical support"])}
  },
  "navigation": {
    "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My properties"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Keys"])},
    "bulletinBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice board"])},
    "timeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time ranges"])},
    "manageSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage / search"])},
    "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our other tools"])},
    "texts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending sms"])},
    "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik services"])},
    "propass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ProPass"])},
    "programmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programming"])},
    "programmation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programming 2"])},
    "keySafe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key safe"])},
    "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service provider"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trash"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "listBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to my sites"])},
    "residenceBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the property"])},
    "buildingBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the building"])},
    "stairwellBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the block"])},
    "old_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classic version of portal"])},
    "vigik3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik+ Services"])}
  },
  "login": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your username"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password"])},
    "connexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "forgottenPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new account"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to your management area"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intratone offers a range of access control and intercom systems for collective housing. A connected concept as simple as it is innovative."])}
  },
  "register": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your username"])},
    "idSubtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your full username will be: ", _interpolate(_named("username"))])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your password"])},
    "anssiPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password must contain at least: "])},
    "passwordRule1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 characters"])},
    "passwordRule2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 capital letter"])},
    "passwordRule3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 lower-case letter"])},
    "passwordRule4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 number"])},
    "passwordRule5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 special character"])},
    "passwordSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password must :"])},
    "passwordRules1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["have a minimum length of 8 characters"])},
    "passwordRules2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meet 3 of these conditions :"])},
    "passwordRules3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["one lower case letter"])},
    "passwordRules4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["one upper case letter"])},
    "passwordRules5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a number"])},
    "passwordRules6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a special character"])},
    "doubleAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate double authentication. Each time you connect to a new device, a one-time code will be sent to the e-mail address you have entered."])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your last name"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your first name"])},
    "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your address"])},
    "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your postcode"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your city"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your country"])},
    "chooseCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your country"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your company"])},
    "industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your sector of activity"])},
    "chooseIndustry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your sector of activity"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone number"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email address"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept the"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms of use"])},
    "alertSpecialCharacter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Letters (a-z), numbers (0-9) and characters (", _interpolate(_named("specialCharacter")), ") are permitted, but spaces are not allowed."])}
  },
  "forgottenPassword": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you forgotten your password?"])},
    "giveMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the e-mail address for your account and we'll send you instructions on how to renew your password."])},
    "mailOrId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail or username"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your new password"])},
    "error": {
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The two passwords are different."])},
      "codeProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the code"])},
      "notRespect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password does not comply with the recommendations"])}
    },
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been changed. You will be redirected to the login page in 10 seconds."])}
  },
  "support": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical support wizard"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use these links to allow transmitters by a technician during a phone call."])}
  },
  "residencesList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My properties"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a site"])},
    "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "listing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " site"]), _normalize([_interpolate(_named("count")), " site"]), _normalize([_interpolate(_named("count")), " sites (", _interpolate(_named("first")), " to ", _interpolate(_named("last")), ")"])])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a property"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify a property"])},
    "caption": {
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur par défaut"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur personnalisée"])}
    },
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "updateStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update status"])}
    },
    "form": {
      "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la liste des résidences"])},
      "types": {
        "title-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full"])},
        "title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplified"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic"])},
        "detail-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your property include several blocks, buildings or halls."])},
        "detail-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your property include a lone stairwell with all the flats."])},
        "detail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your car park will only be controlled by a HF receiverent."])}
      },
      "residence-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property name"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "postal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post code"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Town"])},
      "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik+ County"])},
      "vigik-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify the Vigik+ county entered"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
      "agence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you wish to delete your property ?"])},
      "locative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate property management"])},
      "liberal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional option"])},
      "rgpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR events"])},
      "delete-residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the property"])},
      "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
      "del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "advancedOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced options"])},
      "residenceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property type"])},
      "autorisation": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission by default"])},
        "command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization by default (transmitter)"])},
        "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization by default (mobile key)"])},
        "ipass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iPass' property management"])}
      }
    },
    "modal": {
      "rgpd": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residents"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial buildings"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service providers"])},
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalised"])},
        "detail-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are a landlord or a homeowner association: Access control is used by tenants or landlords. The events will be anonymous."])},
        "detail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are a private or public business: Access control is used by the employees of my business. The events will be visible for 3 months. Using this statute on residents is punishable by law."])},
        "detail-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access control is used by service providers (cleaning companies, gardening, etc.) not employed by my business. The events will be visible for 3 days. Using this statute on residents in punishable by law."])},
        "detail-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security level"])},
        "cage-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block name"])}
      }
    }
  },
  "entityDetails": {
    "tabs": {
      "residenceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property details"])},
      "buildingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building details"])},
      "stairwellDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block details"])},
      "apartmentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flat details"])},
      "authorisations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorisations"])},
      "codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codes"])},
      "centralUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central Units"])},
      "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced options"])},
      "liberale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Craft and professional"])},
      "subaccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-accounts"])},
      "intercoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Displayed on the intercoms"])},
      "connectit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect.it range"])}
    },
    "actions": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
      "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
      "assistedImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assisted import"])},
      "manualImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual import"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
      "stairwellExportA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flat - fob(s) - Nb fob(s) - Name(s) - Username - Name - Signature"])},
      "stairwellExportB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flat - fob(s) - Nb fob(s) - Name(s) - Identifier - P Tel - S Tel - Call - Group"])},
      "stairwellExportC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance list"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact our support"])}
    },
    "externalAccesses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access outside the building"])},
    "internalAccesses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access and equipment"])},
    "accessories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No accessory"]), _normalize([_interpolate(_named("count")), " accessory"]), _normalize([_interpolate(_named("count")), " accessories"])])},
    "resident": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No tenant"]), _normalize([_interpolate(_named("count")), " tenant"]), _normalize([_interpolate(_named("count")), " tenants"])])},
    "floor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ground floor"]), _normalize([_interpolate(_named("count")), " 1st floor"]), _normalize([_interpolate(_named("count")), " 3rd floor"])])},
    "basement": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ground floor"]), _normalize([_interpolate(_named("count")), " first floor"]), _normalize([_interpolate(_named("count")), " third floor"])])}
  },
  "accessCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an access"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
    "codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codes"])},
    "badges": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fobs /Transmitters (", _interpolate(_named("count")), ")"])},
    "opendoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open access"])}
  },
  "apartmentsList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Flats, houses, villas (", _interpolate(_named("count")), ")"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a flat"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "firstResident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First record"])},
      "residents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant(s)"])},
      "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fob(s)"])}
    },
    "noAuthorizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the rights to consult the flats."])}
  },
  "intercomsList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Displayed on the intercoms (", _interpolate(_named("count")), ")"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
    }
  },
  "codesList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The code by entrance"])},
    "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " access"])},
    "keypadsCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keypads"])},
    "phoneCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone codes"])}
  },
  "centralUnitsList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central unit"])},
    "listing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " central"]), _normalize([_interpolate(_named("count")), " central"]), _normalize([_interpolate(_named("count")), " centrals (", _interpolate(_named("first")), " to ", _interpolate(_named("last")), ")"])])},
    "realTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real time"])},
    "readWrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read / write"])},
    "lora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect-It compatible"])},
    "searchCentral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search a central unit."])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial number"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "updateStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update status"])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])}
    }
  },
  "keyCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create fob"])},
    "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fob"])},
    "serial": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["n°", _interpolate(_named("serial"))])},
    "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete fob ", _interpolate(_named("serial")), "?"])},
    "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Key"])},
    "kibolt": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kibolt key"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This key must be loaded"])}
    },
    "copie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badge copied"])},
    "colors": {
      "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black"])},
      "beige": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beige"])},
      "green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green"])},
      "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blue"])},
      "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red"])},
      "brown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brown"])},
      "grey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grey"])},
      "yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yellow"])},
      "orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orange"])},
      "purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purple"])},
      "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
    },
    "teleco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transmitter"])}
  },
  "residenceCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a property"])}
  },
  "residentCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a record"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "mainNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary number"])},
    "sideNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary number"])},
    "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete resident ", _interpolate(_named("nom")), "?"])}
  },
  "stairwellCard": {
    "createStairwell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a block"])},
    "createBuilding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a building"])},
    "accesses": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Access (", _interpolate(_named("count")), ")"])},
    "stairwells": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " block"]), _normalize([_interpolate(_named("count")), " blocks"])])},
    "apartments": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " flat"]), _normalize([_interpolate(_named("count")), " flats"])])}
  },
  "subaccountsList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Subaccounts (", _interpolate(_named("count")), ")"])},
    "arrayColumns": {
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "fisrtName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
    }
  },
  "agencesList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Agencies (", _interpolate(_named("count")), ")"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])}
    }
  },
  "text": {
    "userDataLaws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have the right to access, modify, rectify and delete data concerning you (art. 34 of the French Data Protection Act). To exercise this right, please contact"])},
    "contactEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email : "])},
    "emailIntratoneSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intratone.uk.com"])},
    "linkEmailIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support"])},
    "linkEmailDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intratone.uk.com"])},
    "contactAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address : 114 Power Road"])},
    "contactCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W4 5PY LONDON"])},
    "contactPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tel : 020 8037 9017"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
  },
  "brands": {
    "windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windows"])},
    "apple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple"])}
  },
  "bloc_erp": {
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])},
    "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "visio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video call"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "appPC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["required for PC video call"])},
    "telsec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary Phone"])},
    "visiosec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video call to secondary phone"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colour"])}
  },
  "redirect": {
    "https": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirection vers le site en HTTPS"])},
    "http": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirection vers le site en HTTP"])}
  }
}